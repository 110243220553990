<template>
  <router-link
    tag="div"
    class="menu_element_block flex align-middle items-center pl-4"
    :to="'/' + path"
  >
    <icon
      :data="icon"
      height="25"
      width="25"
      class="menu_element_icon"
      color="#8E8EA9"
      original
    />
    <div class="menu_element ml-4 text-c18 w-3/4 text-left">
      {{ menu }}
    </div>
  </router-link>
</template>

<script>
import right from '../../assets/icons/right.svg'
export default {
  name: '',
  props: {
    menu: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    path: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      resultat: '#ffffff',
      icons: {
        right
      }
    }
  }
}
</script>

<style lang="scss">
@import '../../assets/styles/sass/mixin';
.is-active-position-little {
}
.menu_element_block{
  position: relative;
  cursor: pointer;
  width: 100%;
  height: 48px;
  font-weight: 400;
  letter-spacing: 0.0168em;
  color: #8E8EA9;
  .menu_element{
    color: #8E8EA9
  }
  &.router-link-active{
    padding: 1rem 1rem;
    height: 48px;
    border-radius: 10px;
    color: white;
    background-color: $oho-blue-clear;
    .menu_element{
      color: white
    }
    .avtive_position{
      visibility: visible;
      background: none;
    }
    .menu_element_icon{
      path{
        fill: white!important;
      }
    }
  }
}

.menu_element_block:hover{
    padding: 1rem 1rem;
    height: 48px;
    border-radius: 10px;
    background-color: $hover-side;
    .menu_element{
      color: #8E8EA9
    }
  .menu_element_icon{
    path{
      fill: #8E8EA9!important;
    }
  }
}
</style>
