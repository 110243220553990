<template>
  <div class="app-header">
    <logout
      v-if="activelogout"
      :activation="activelogout"
      @oga="retourOut"
    />
    <div class="web-header w-full">
      <div class="w-full h-full header-container flex">
        <div
          class="flex-one w-1/5 pl-4 flex items-center logo-section h-full text-c26 font-c8"
        >
          <!--          <img src="../../assets/images/png/logo.png" alt="" class="md:h-12 w-12 cursor-pointer">-->
          <!--          Portfolios-->
        </div>

        <div class="flex-one w-4/5  h-full option-list  items-center flex justify-end mr-4">
          <div
            class="flex-two text-xl action cursor-pointer font-normal flex items-center"
          >
            <icon
              :data="icons.notif"
              height="30"
              width="30"
              original
            />
          </div>

          <div class="h-12 w-c1 bg-gray ml-4" />

          <div
            class="text-xl w-1/6 cursor-pointer font-normal flex mr-4"
          >
            <div
              class="w-full text-right font-c4 text-c18"
              @click="activelogout = true"
            >
              <div v-if="user !== null">
                <div v-if="user.fullname.indexOf(' ') > 0">
                  {{ user.fullname.substr(0, user.fullname.indexOf(' ')) }}
                </div>
                <div v-if="user.fullname.indexOf(' ') > 0">
                  {{ user.fullname.substr(user.fullname.indexOf(' '), user.fullname.length) }}
                </div>
                <div v-if="user.fullname.indexOf(' ') < 0">
                  {{ user.fullname }}
                </div>
              </div>

              <div v-if="user === null">
                <div>Sam</div>
                <div>Winner</div>
              </div>
            </div>
          </div>

          <div
            class="flex items-center"
            @click="activelogout = true"
          >
            <div class="h-16 w-16 rounded-50 p-c1 borderS">
              <img
                src="../../assets/images/jpg/login.jpg"
                alt=""
                class="w-full h-full rounded-50"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import downs from '../../assets/icons/down.svg'
import agence from '../../assets/icons/agence.svg'
import notif from '../../assets/icons/notification.svg'
import logout from '../popup/logOut'

export default {
  name: 'TheHeader',
  components: {
    logout
  },

  data () {
    return {
      name: 'patou',
      activelogout: false,
      showMobileMenu: false,
      activeEvenement: false,
      isLog: null,
      activeLogin: false,
      activDefile: false,
      activeAgence: false,
      broker: null,
      user: null,
      icons: {
        downs,
        agence,
        notif
      }
    }
  },

  mounted () {
    if (this.$store.getters.token !== null){
      this.user = this.parseJwt(this.$store.getters.token)
    } else {
      windows.location.reload()
    }
  },

  methods: {
    retourOut(answwer){
      this.activelogout = answwer
    },

    parseJwt (token) {
      const base64Url = token.split('.')[1];
      const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      const jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''));
      return JSON.parse(jsonPayload)
    }
  }
}
</script>

<style lang="scss" scoped>
@import "../../assets/styles/sass/variables";

.app-header {
  width: 100%;
  background: white;
  z-index: 1;
  height: auto;
  color: #000000;
}
.head1{
  background-color: yellow;
  .lien{
    background: #8451D2;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 14px;
  }
}
.web-header{
  font-size: 18px;
}
.dropdownVip{
  position: absolute;
  right: 300px;
  background-color: #fff;
  width: 10%;
  box-shadow: 0px 1px 2px 0px #0000004f;
  border-radius: 0px 0px 5px 5px;
  .item{
    font-size: 18px;
    font-weight: 600;
    cursor: pointer;
  }
  .item:hover{
    color: blue;
  }
}
.header-container{
  padding-right: 40px;
  padding-left: 40px;
  height: 99px;
  //border-bottom: 3px solid #E8F1FD;
}
.shadow{
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);
}
.diviserH{
  width: 1px;
  height: 2rem;
  background-color: #E9E9E9;
}
.borderS{
  border: 1.63636px solid #DFE0EB
}
.suivre{
  font-weight: normal;
  font-size: 14.5321px;
  line-height: 17px;
  font-family: 'Rubik', sans-serif;
}

.button {
  cursor: pointer;
  padding-top: 10px;
  background-color: yellow;
  height: 50px;
  font-weight: bold;
  font-family: $font-default;
  font-size: 18px;
  line-height: 18px;
  border: none;
}
.button:hover{
  background-color: #1f7fc8;
}
.mobile-header {
  display: none;
}
.button-section  {
  padding-top: 10px;
  width: 15%;
}
.action {
  max-width: 13%;
}
.action:hover {
  color: yellow;
}
.burger {
  cursor: pointer;
  .line {
    height: .18rem;
    width: 2rem;
    background-color: yellow;
  }
}

.close-burger  {
  cursor: pointer;
  .line {
    height: .2rem;
    width: 3rem;
    background-color: yellow;
  }
  .line:first-child {
    transform: rotate(-40deg);
  }
  .line:last-child {
    transform: rotate(40deg);
  }
}
.mobile-menu-items {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.26s ease;
}
.mobile-menu-items-active {
  transform: scaleY(1);
}
.premier{
  border-radius: 10px 0px 0px 10px;
}
.second{
  border-radius: 0px 10px 10px 0px;
}
img{
  object-fit: cover;
}

@media screen and (max-width: 1024px) and (min-width: 770px){
  .header-container{
    padding: 40px;
    height: 5rem;
  }
  .action{
    margin-right: 2rem !important;
  }
  img{
    height: 3rem;
  }
  .ico{
    height: 2rem;
    width: 2rem;
  }
  .button {
    font-size: 15px;
    height: 2.6rem;
  }
}

@media screen and (max-width: 769px) and (min-width: 701px){
  .header-container{
    padding: 20px;
    height: 5rem;
  }
  .action{
    margin-right: 2rem !important;
    font-size: 18px;
  }
  img{
    height: 2rem;
  }
  .button {
    font-size: 14px;
    height: 2.5rem;
  }
  .user{
    display: none;
  }
  .ico{
    height: 2rem;
  }

}

@media only screen and (max-width: 700px) {
  .web-header {
    display: none;
  }
  .mobile-header {
    display: block;
    background-color: white;
    z-index: 999;
  }
  .mobile-menu-items{
    background-color: white;
  }
  .header-container{
    padding: 0px;
    height: 4rem;
  }
  .mobile-header{
    height: 4rem;
  }
  .niveau{
    font-size: 18px;
  }
}
</style>
